import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from '@tanstack/react-query'
import IWidget from '@/interfaces/IWidget'

const all: QueryFunction<IWidget[], [string, number | null, boolean?]> = ({ queryKey: [ _, clientId, isAdmin ] }) => {
  return axios.get(`${isAdmin ? 'admin/' : ''}widgets`, {
    params: {
      clientId,
    },
  }).then<IWidget[]>(({ data }) => data)
}

const find: QueryFunction<any[], [string, number, number | null, boolean?]> = ({ queryKey: [ _, id, clientId, isAdmin ] }) => {
  return axios.get(`${isAdmin ? 'admin/' : ''}widgets/${id}`, {
    params: {
      clientId,
    },
  }).then<any[]>(({ data }) => data)
}

const update: MutationFunction<AxiosResponse<void>, {
  widget: IWidget
  clientId: number | null
  isAdmin?: boolean
  signal: AbortSignal
}> = ({ widget: { id, layouts }, clientId, isAdmin, signal }) => {
  return axios.put<IWidget, AxiosResponse<void>>(`${isAdmin ? 'admin/' : ''}widgets/${id}`, layouts, {
    signal,
    params: {
      clientId,
    },
  })
}

const disable: MutationFunction<AxiosResponse<void>, {
  id: number
  clientId: number | null
  isAdmin?: boolean
}> = ({ id, clientId, isAdmin }) => {
  return axios.delete<void>(`${isAdmin ? 'admin/' : ''}widgets/${id}`, {
    params: {
      clientId,
    },
  })
}

const $Widget = {
  all,
  find,
  update,
  disable,
}

export default $Widget
