import React, { useContext, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { UserContext } from '@/contexts/User'
import Select from 'react-select'
import $School from '@/services/School'

const Schools: React.FC = () => {
  const { $user, setSchoolId } = useContext(UserContext)

  const $schools = useQuery({
    queryFn: $School.all,
    queryKey: [
      'schools',
    ],
  })

  const schools = useMemo(() => {
    if (!$schools.data)
      return []

    return $schools.data.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  }, [ $schools.data ])

  const defaultValue = useMemo(() => {
    if (!$user.data)
      return null

    const { id, name } = $user.data.userSchool.school

    return {
      value: id,
      label: name,
    }
  }, [ $user.data ])

  return (
    <Select
      className="react-select-outline"
      classNamePrefix="react-select"
      options={schools}
      onChange={option => {
        if (option) {
          setSchoolId(option.value)
        }
      }}
      value={defaultValue}
      placeholder="Selecione uma escola"
      isSearchable={true}
      isClearable={false}
      isLoading={$schools.isPending || $user.isPending}
    />
  )
}

export default Schools
