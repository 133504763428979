import React, { createContext, useContext, useMemo, useState } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { Outlet } from 'react-router-dom'
import { AuthContext } from '@/contexts/Auth'
import ISchool from '@/interfaces/ISchool'
import IUser from '@/interfaces/IUser'
import $Auth from '@/services/Auth'

type Props = {
  school: ISchool | null
  schoolId: number | null
  setSchoolId: React.Dispatch<React.SetStateAction<number | null>>
  $user: UseQueryResult<IUser, Error>
}

export const UserContext = createContext<Props>({} as Props)

const UserProvider: React.FC = () => {
  const { token } = useContext(AuthContext)

  const [ schoolId, setSchoolId ] = useState<number | null>(null)

  const $user = useQuery({
    queryFn: $Auth.user,
    enabled: !!token,
    queryKey: [
      'user', token, schoolId,
    ],
  })

  const school = useMemo(() => {
    if (!$user.data)
      return null

    return $user.data.school
  }, [$user.data])

  return (
    <UserContext.Provider
      value={{
        school,
        schoolId,
        setSchoolId,
        $user,
      }}
    >
      {$user.isSuccess && (
        <Outlet />
      )}
    </UserContext.Provider>
  )
}

export default UserProvider
