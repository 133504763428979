import axios from 'axios'
import { QueryFunction } from '@tanstack/react-query'
import ISchool from '@/interfaces/ISchool'

const all: QueryFunction<ISchool[]> = () => {
  return axios.get('schools').then(({ data }) => data)
}

const $School = {
  all,
}

export default $School
