import axios from 'axios'
import { QueryFunction } from '@tanstack/react-query'
import IPlatform from '@/interfaces/IPlatform'

const all: QueryFunction<IPlatform[]> = () => {
  return axios.get('platforms').then(({ data }) => data)
}

const $Platform = {
  all,
}

export default $Platform
