import axios from 'axios'
import { QueryFunction } from '@tanstack/react-query'
import IUser from '@/interfaces/IUser'
import IAdmin from '@/interfaces/IAdmin'

const user: QueryFunction<IUser, [ string, string | null, number | null ]> = ({ queryKey: [ _, token, schoolId ] }) => {
  return axios.get('auth/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      schoolId,
    },
  }).then(({ data }) => data)
}

const admin: QueryFunction<IAdmin, [ string, string | null ]> = ({ queryKey: [ _, token ] }) => {
  return axios.get('admin/auth/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(({ data }) => data)
}

const $Auth = {
  user,
  admin,
}

export default $Auth
